<template>
  <v-card
    elevation="4"
    class="data-table"
    :class="[
      itemsLoading ? 'data-table--loading' : '',
      canBeSelected ? 'data-table--selectable' : '',
    ]"
  >
    <v-data-table
      calculate-widths
      :headers="headers"
      :items="items"
      :items-per-page.sync="itemsPerPage"
      :page.sync="page"
      :search="searchTerm"
      :sort-by="sortBy.value"
      :sort-desc="sortDesc"
      hide-default-footer
      hide-default-header
      class="elevation-0 custom-data-table"
      :item-class="itemClass"
    >
      <template v-if="itemsLoading" v-slot:body>
        <template v-if="$vuetify.breakpoint.lgAndDown">
          <logs-table-item-skeleton-mobile v-for="item in 5" :key="item" />
        </template>
        <template v-else>
          <logs-table-sort-skeleton />
          <logs-table-skeleton-item />
        </template>
      </template>

      <template v-slot:no-data>
        <slot name="no-data" />
      </template>

      <template v-slot:no-results>
        <DataIteratorNoResultsContainer
          v-if="searchTerm"
          @clearAllFilters="$emit('clearFilters')"
          :searchTerm="searchTerm"
        />
      </template>
      <template
        v-if="!$vuetify.breakpoint.mobile && items.length"
        v-slot:header="{ isMobile, props, on }"
      >
        <table-custom-sort
          v-if="!itemsLoading"
          :isMobile="isMobile"
          :props="props"
          :v-on="on"
          v-on="$listeners"
        />
      </template>

      <template v-slot:item.domain="{ item }">
        <div>
          <div class="d-flex align-center">
            <a
              class="font-weight-bold"
              target="__blank"
              :href="`https://${item.domain}`"
            >
              {{ item.domain }}
            </a>

            <domains-nameserver-checker
              v-if="item.nsStatus != 'ok'"
              :domain="item.domain"
              :onboardingSubdomain="null"
              :dnsSettings="service.dns_settings"
              :ipv4Addresses="serverAccount.ipv4_addresses"
              :hasDnsServer="service.dns_server_enabled"
              :nameservers="serverAccount.nameservers"
            />
          </div>
          <span
            class="p-4 gray--text text--darken-1"
            v-if="item.redirect_enabled"
          >
            <i18next :translation="$t('heading.hosting.domains.redirectedTo')">
              <template #domain>
                <a
                  class="domain-link font-weight-bold"
                  target="__blank"
                  :href="item.redirect_url"
                >
                  {{ item.redirect_url }}
                </a>
              </template>
            </i18next>
          </span>
        </div>
      </template>

      <template v-slot:item.ssl_status="{ index, item }">
        <div
          v-if="
            [
              'pending',
              'in_progress',
              'awaiting_dns_propagation',
              'failed',
            ].includes(item.ssl_order_status)
          "
        >
          <pending-ssl-indicator :domain="item" />
        </div>
        <v-progress-circular
          v-else-if="item.sslStatusLoading"
          indeterminate
          color="primary"
          size="20"
        ></v-progress-circular>
        <ssl-indicator
          v-else
          class="ssl-button d-flex align-center"
          @click.native="showEditSslModal(item)"
          :ssl="
            item.sslDetectedCert
              ? {
                  certificate_installed: true,
                  certificate: item.sslDetectedCert,
                }
              : {
                  certificate_installed: false,
                  certificate: {},
                }
          "
          :text="
            item.sslDetectedCert
              ? item.sslDetectedCert.self_signed
                ? $t('general.sslProvider.selfSigned')
                : item.sslDetectedCert.issuer_name
              : $t('general.sslProvider.none')
          "
          :textClass="
            item.sslDetectedCert
              ? !(
                  item.sslDetectedCert.self_signed ||
                  !item.sslDetectedCert.name_match
                )
                ? 'success--text'
                : 'error--text'
              : 'error-text'
          "
        />
      </template>

      <template v-slot:item.type="{ item }">
        <div class="d-flex">
          <slot name="type" v-bind="item"> </slot>
        </div>
      </template>

      <template v-slot:item.document_root="{ item }">
        <span class="gray--text text--darken-1">{{ item.document_root }}</span>
      </template>

      <template v-slot:item.actions="{ index, item }">
        <div class="d-flex actions-row">
          <v-tooltip
            v-if="item.actions.includes('set_primary_domain')"
            transition="custom-tooltip"
            open-delay="150"
            bottom
            z-index="99"
            offset-overflow
            nudge-bottom="4px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                icon
                small
                :loading="item.editSslButtonLoading"
                @click.native="$emit('action-button-set-primary-domain', item)"
                :class="{ 'bgcolor-transparent': item.type === 'main' }"
                :disabled="item.type === 'main'"
              >
                <v-icon v-if="item.type === 'main'">$starFullPremiumIcon</v-icon>
                <v-icon v-else>$starEmptyIcon</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('button.setAsPrimaryDomain') }}</span>
          </v-tooltip>

          <v-tooltip
            v-if="item.manage_ssl && service.getUserPrivileges(service.privileges, 'hosting.manage_ssl')"
            transition="custom-tooltip"
            open-delay="150"
            bottom
            z-index="99"
            offset-overflow
            nudge-bottom="4px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                icon
                small
                :loading="item.editSslButtonLoading"
                @click.native="$emit('action-button-ssl-edit', item)"
              >
                <v-icon>$lockoutline</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("button.ssl.edit") }}</span>
          </v-tooltip>

          <v-tooltip
            v-if="item.actions.includes('redirect_domain') || item.actions.includes('update_document_root')"
            transition="custom-tooltip"
            open-delay="150"
            bottom
            z-index="99"
            offset-overflow
            nudge-bottom="4px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                icon
                small
                @click="$emit('action-button-edit', item)"
              >
                <v-icon>$edit2</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("button.domain.edit") }}</span>
          </v-tooltip>

          <v-tooltip
            v-if="item.type != 'main'"
            transition="custom-tooltip"
            open-delay="150"
            bottom
            z-index="99"
            offset-overflow
            nudge-bottom="4px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                icon
                small
                @click="$emit('action-button-delete', item)"
              >
                <v-icon>$thrash</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("button.domain.delete") }}</span>
          </v-tooltip>
        </div>
      </template>

      <template
        v-if="$vuetify.breakpoint.mobile"
        v-slot:item="{ item, headers }"
      >
        <tr>
          <td class="w-100">
            <div class="mobile-table-item">
              <div class="mobile-table-item__values">
                <div class="mobile-table-item__row">
                  <div
                    class="mobile-table-item__header"
                    @click="$emit('update:sortBy', headers[0])"
                  >
                    {{ $t("table.header.domain") }}
                  </div>
                  <div class="mobile-table-item__value">
                    <div>
                      <div class="d-flex align-center">
                        <a
                          class="font-weight-bold"
                          target="__blank"
                          :href="`https://${item.domain}`"
                        >
                          {{ item.domain }}
                        </a>

                        <domains-nameserver-checker
                          v-if="item.nsStatus != 'ok'"
                          :domain="item.domain"
                          :onboardingSubdomain="null"
                          :dnsSettings="service.dns_settings"
                          :ipv4Addresses="serverAccount.ipv4_addresses"
                          :hasDnsServer="service.dns_server_enabled"
                          :nameservers="serverAccount.nameservers"
                        />
                      </div>
                      <span
                        class="p-4 gray--text text--darken-1"
                        v-if="item.redirect_enabled"
                      >
                        <i18next
                          :translation="
                            $t('heading.hosting.domains.redirectedTo')
                          "
                        >
                          <template #domain>
                            <a
                              class="domain-link font-weight-bold"
                              target="__blank"
                              :href="item.redirect_url"
                            >
                              {{ item.redirect_url }}
                            </a>
                          </template>
                        </i18next>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="mobile-table-item__row">
                  <div
                    class="mobile-table-item__header"
                    @click="$emit('update:sortBy', headers[1])"
                  >
                    {{ $t("table.header.ssl") }}
                  </div>
                  <div class="mobile-table-item__value">
                    <div
                      v-if="
                        [
                          'pending',
                          'in_progress',
                          'awaiting_dns_propagation',
                          'failed',
                        ].includes(item.ssl_order_status)
                      "
                    >
                      <pending-ssl-indicator :domain="item" />
                    </div>
                    <v-progress-circular
                      v-else-if="item.sslStatusLoading"
                      indeterminate
                      color="primary"
                      size="20"
                    ></v-progress-circular>
                    <ssl-indicator
                      v-else
                      class="ssl-button d-flex align-center"
                      @click.native="showEditSslModal(item)"
                      :ssl="
                        item.sslDetectedCert
                          ? {
                              certificate_installed: true,
                              certificate: item.sslDetectedCert,
                            }
                          : {
                              certificate_installed: false,
                              certificate: {},
                            }
                      "
                      :text="
                        item.sslDetectedCert
                          ? item.sslDetectedCert.self_signed
                            ? $t('general.sslProvider.selfSigned')
                            : item.sslDetectedCert.issuer_name
                          : $t('general.sslProvider.none')
                      "
                      :textClass="
                        item.sslDetectedCert
                          ? !(
                              item.sslDetectedCert.self_signed ||
                              !item.sslDetectedCert.name_match
                            )
                            ? 'success--text'
                            : 'error--text'
                          : 'error-text'
                      "
                    />
                  </div>
                </div>
                <div class="mobile-table-item__row">
                  <div
                    class="mobile-table-item__header"
                    @click="$emit('update:sortBy', headers[2])"
                  >
                    {{ $t("table.header.type") }}
                  </div>
                  <div class="mobile-table-item__value">
                    <slot name="type" v-bind="item"> </slot>
                  </div>
                </div>

                <div class="mobile-table-item__row" v-if="service.hosting_server_type !== 'wp-cloud'">
                  <div
                    class="mobile-table-item__header"
                    @click="$emit('update:sortBy', headers[3])"
                  >
                    {{ $t("table.header.documentRoot") }}
                  </div>
                  <div class="mobile-table-item__value">
                    <span class="gray--text text--darken-1">
                      {{ item.document_root }}
                    </span>
                  </div>
                </div>

                <div class="mobile-table-item__row">
                  <div class="mobile-table-item__header">
                    {{ $t("table.header.actions") }}
                  </div>
                  <div class="mobile-table-item__value">
                    <v-tooltip
                      v-if="item.actions.includes('set_primary_domain')"
                      transition="custom-tooltip"
                      open-delay="150"
                      bottom
                      z-index="99"
                      offset-overflow
                      nudge-bottom="4px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-on="on"
                          v-bind="attrs"
                          icon
                          small
                          :loading="item.editSslButtonLoading"
                          @click.native="$emit('action-button-set-primary-domain', item)"
                          :class="{ 'bgcolor-transparent': item.type === 'main' }"
                          :disabled="item.type === 'main'"
                        >
                          <v-icon v-if="item.type === 'main'">$starFullPremiumIcon</v-icon>
                          <v-icon v-else>$starEmptyIcon</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('button.setAsPrimaryDomain') }}</span>
                    </v-tooltip>

                    <v-tooltip
                      transition="custom-tooltip"
                      open-delay="150"
                      bottom
                      z-index="99"
                      offset-overflow
                      nudge-bottom="4px"
                      v-if="
                        service.getUserPrivileges(
                          service.privileges,
                          'hosting.manage_ssl'
                        )
                      "
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-on="on"
                          v-bind="attrs"
                          icon
                          small
                          :loading="item.editSslButtonLoading"
                          @click.native="$emit('action-button-ssl-edit', item)"
                        >
                          <v-icon>$lockoutline</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("button.ssl.edit") }}</span>
                    </v-tooltip>

                    <v-tooltip
                      v-if="item.actions.includes('redirect_domain') || item.actions.includes('update_document_root')"
                      transition="custom-tooltip"
                      open-delay="150"
                      bottom
                      z-index="99"
                      offset-overflow
                      nudge-bottom="4px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-on="on"
                          v-bind="attrs"
                          icon
                          small
                          @click="$emit('action-button-edit', item)"
                        >
                          <v-icon>$edit2</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("button.domain.edit") }}</span>
                    </v-tooltip>
                    <v-tooltip
                      v-if="item.type != 'main'"
                      transition="custom-tooltip"
                      open-delay="150"
                      bottom
                      z-index="99"
                      offset-overflow
                      nudge-bottom="4px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-on="on"
                          v-bind="attrs"
                          icon
                          small
                          @click="$emit('action-button-delete', item)"
                        >
                          <v-icon>$thrash</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("button.domain.delete") }}</span>
                    </v-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </template>

      <template v-slot:footer="{ props }" v-if="items.length">
        <div>
          <data-iterator-footer
            :page="props.pagination.page"
            :numberOfPages="props.pagination.pageCount"
            :possibleItemsPerPage="possibleItemsPerPage"
            :listStyle="listStyle"
            :itemsPerPage="props.pagination.itemsPerPage"
            v-on="$listeners"
          >
          </data-iterator-footer>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import DataIteratorMixin from "../../../mixins/DataIteratorMixin";
import CustomTableMixin from "../../../mixins/CustomTableMixin";

import DataIteratorFooter from "../DataIteratorFooter.vue";
import TableCustomSort from "../TableCustomSort.vue";
import LogsTableSkeletonItem from "./LogsTableSkeletonItem.vue";
import LogsTableSortSkeleton from "./LogsTableSortSkeleton.vue";
import LogsTableItemSkeletonMobile from "./LogsTableItemSkeletonMobile.vue";
import DomainsNameserverChecker from "../../modal/DomainsNameserverChecker.vue";
import DataIteratorNoResultsContainer from "../DataIteratorNoResultsContainer.vue";

import SslIndicator from "../../security/SslIndicator.vue";
import PendingSslIndicator from "@/components/security/PendingSslIndicator.vue";

export default {
  components: {
    DataIteratorFooter,
    TableCustomSort,
    LogsTableSkeletonItem,
    LogsTableSortSkeleton,
    LogsTableItemSkeletonMobile,
    DomainsNameserverChecker,
    SslIndicator,
    PendingSslIndicator,
    DataIteratorNoResultsContainer,
  },
  data: function () {
    return {
      canBeSelected: false,
    };
  },
  methods: {
    mockSslStatus: function () {
      return Math.random() > 0.5;
    },
    showEditSslModal(item) {
      if (item.manage_ssl) {
        this.$emit("action-button-ssl-edit", item);
      }
    },
  },
  mixins: [DataIteratorMixin, CustomTableMixin],
  props: {
    headers: Array,
    itemsTotal: Number,
    itemsLoading: Boolean,
    itemClass: Function,
    serverAccount: Object,
    service: Object,
  },
};
</script>

<style lang="scss" scoped>
.bgcolor-transparent{
  background-color: transparent!important;
}
.v-card {
  border-radius: 8px;
}

.params {
  max-width: 450px;
}

.domain-link {
  color: map-get($text, heading);
  &:hover {
    color: map-get($info, primary);
  }
}

a {
  color: map-get($text, headings);
  transition: color 0.24s ease;
  &:hover {
    color: map-get($primary, base);
  }
}

.data-table .actions-row {
  justify-content: flex-start;
  gap: 12px;
}

.data-table::v-deep {
  .text-start {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    height: 60px;
    color: map-get($gray, darken-1);
  }
}
.ssl-button::v-deep {
  cursor: pointer;
  .v-icon {
    margin-right: 8px;
  }
}
.data-table .actions-row:last-child {
  justify-content: flex-end;
}
.custom-sort::v-deep {
  .sort-item:last-child {
    justify-content: flex-end;
    display: flex;
    align-items: center;
    height: 56px;
  }
}

.v-application--is-rtl .ssl-button::v-deep {
  .v-icon {
    margin-right: 0px;
    margin-left: 8px;
  }
}

.data-table {
  .actions-row {
    .v-btn {
      color: var(--v-text-darken1) !important;
    }
  }
}
</style>
