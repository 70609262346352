import Api from "@/apis/Api";

export default {
	methods: {
		// ADD DOMAIN
		showAddDomainModal() {
			this.resetModal();
			this.modalOptions.title = this.$t(
				"heading.hosting.domains.modal.create.title"
			);
			this.modalOptions.icon = "$plus";
			this.modalOptions.buttons.unshift({
				label: this.$t("button.domain.add"),
				color: "primary",
				onclick: (modal) => {
					if (this.service.server_settings.domain_transfer_allowed) {
						modal.$refs.form.validate() && this.verifyDomain(modal.formValues);
					} else {
						modal.$refs.form.validate() && this.addDomain(modal.formValues);
					}
				},
			});

			let parentDomains = [];
			for (const domain of this.items) {
				if (domain.type == "sub" || domain.type == "alias") {
					continue;
				}
				parentDomains.push({
					label: domain.domain,
					value: domain.domain,
				});
			}

			const domainTypes = this.supportedDomainTypes.map((type) => {
				return {
					label: this.$t(`form.option.${type}`),
					value: type,
				};
			});

			this.modalOptions.formFields = [
				{
					label: this.$t("form.label.domain"),
					name: "domain",
					type: "text",
					tooltip: "tooltip.add_domain.domain",
					rules: [
						(v) =>
							!!v ||
							this.$t("form.validation.required", {
								field: this.$t("form.label.domain"),
							}),
						(v) =>
							(v && v.length <= 255) ||
							this.$t("form.validation.maxLength", {
								field: this.$t("form.label.domain"),
								length: 255,
							}),
						(v) =>
							(v && /^[a-zA-Z0-9-_.]+$/.test(v)) ||
							this.$t("form.validation.valid", {
								field: this.$t("form.label.domain"),
							}),
					],
					placeholder: this.$t("form.placeholder.domain"),
					hidden: false,
				},
				{
					label: this.$t("form.label.domain"),
					type: "text-select",
					suffix: ".",
					textName: "domain",
					selectName: "parent_domain",
					tooltip: "tooltip.add_domain.domain",
					options: parentDomains,
					hidden: true,
					rules: [
						(v) =>
							!!v ||
							this.$t("form.validation.required", {
								field: this.$t("form.label.domain"),
							}),
						(v) =>
							(v && /^[a-zA-Z0-9-_.]+$/.test(v)) ||
							this.$t("form.validation.valid", {
								field: this.$t("form.label.domain"),
							}),
						(v) =>
							(v && v.length <= 255) ||
							this.$t("form.validation.maxLength", {
								field: this.$t("form.label.domain"),
								length: 255,
							}),
					],
				},
				{
					label: this.$t("form.label.type"),
					name: "type",
					tooltip: "tooltip.add_domain.type",
					type: "select",
					options: domainTypes,
					// options: [
					//   {
					//     label: this.$t('form.option.addon'),
					//     value: "addon",
					//   },
					//   {
					//     label: this.$t('form.option.subdomain'),
					//     value: "sub",
					//   },
					// ],
					required: true,
					onChange: (values) => {
						if (values.type == "sub") {
							for (const field of this.modalOptions.formFields) {
								if (field.name == "domain") {
									field.hidden = true;
								}
								if (field.selectName == "parent_domain") {
									field.hidden = false;
								}
							}
						} else {
							for (const field of this.modalOptions.formFields) {
								if (field.name == "domain") {
									field.hidden = false;
								}
								if (field.selectName == "parent_domain") {
									field.hidden = true;
								}
							}
						}
					},
				},
			];
			this.modalOptions.item = {
				type: domainTypes[0].value,
				parent_domain: parentDomains[0].value,
			};
			this.modalOptions.open = true;
		},
		addDomain(formData) {
			this.modalOptions.persistent = true;
			this.modalOptions.submitting = true;
			this.modalOptions.submittingSuccess = "";
			this.modalOptions.submittingError = "";

			let domainType = this.$t("form.label.domain");

			let postData = { ...formData };
			if (postData.type == "sub") {
				postData.domain += "." + postData.parent_domain;
				domainType = this.$t("form.label.subdomain");
			}

			Api.post(`/server-accounts/${this.serverAccount.id}/domains`, postData)

				.then(() => {
					this.modalOptions.submittingSuccess = this.$t(
						"notification.hosting.domain.create.success",
						{ type: domainType }
					);
					this.highlightItem = postData;
					Api.hosting(this.serverAccount.id).resetCache();
					this.reloadData(true);

					this.$store.dispatch("addAlert", {
						success: true,
						successMessage: this.modalOptions.submittingSuccess,
					});
					this.modalOptions.open = false;
				})
				.catch((error) => {
					this.modalOptions.submittingError = Api.getErrorMessage(error);

					this.$store.dispatch("addAlert", {
						success: false,
						errorMessage: this.modalOptions.submittingError,
					});
				})
				.finally(() => {
					this.modalOptions.persistent = false;
					this.modalOptions.submitting = false;
				});
		},

		// EDIT DOMAIN

		showEditModal(item) {
			this.resetModal();
			this.modalOptions.item = item;
			this.modalOptions.title = this.$t(
				"heading.hosting.domains.modal.update.title",
				{ domain: `<span style="text-wrap: nowrap">${item.domain}</span>` }
			);
			this.modalOptions.icon = "$edit";
			this.modalOptions.buttons.unshift({
				label: this.$t("button.update"),
				color: "primary",
				onclick: (modal) => {
					modal.$refs.form.validate() &&
					this.updateDomain(item, modal.formValues);
				},
			});
			this.modalOptions.formFields = [
				{
					label: this.$t("form.label.documentRoot"),
					name: "document_root",
					tooltip: "tooltip.edit_domain.document_root",
					type: "text",
					rules: [
						(v) =>
							!!v ||
							this.$t("form.validation.required", {
								field: this.$t("form.label.documentRoot"),
							}),
						(v) =>
							(v && v.length <= 255) ||
							this.$t("form.validation.maxLength", {
								field: this.$t("form.label.documentRoot"),
								length: 255,
							}),
					],
					placeholder: this.$t("form.placeholder.documentRoot"),
					hidden: item.type == "main",
				},
				{
					label: this.$t("form.label.redirectDomain"),
					name: "redirect_enabled",
					type: "checkbox",
					onChange: (values) => {
						for (const field of this.modalOptions.formFields) {
							if (field.name == "redirect_url") {
								field.hidden = !values.redirect_enabled;
								break;
							}
						}
					},
				},
				{
					label: this.$t("form.label.redirectUrl"),
					name: "redirect_url",
					tooltip: "tooltip.edit_domain.redirect_url",
					type: "text",
					hidden: !item.redirect_enabled,
				},
			];
			this.modalOptions.open = true;
		},
		updateDomain(item, formData) {
			this.modalOptions.persistent = true;
			this.modalOptions.submitting = true;
			this.modalOptions.submittingSuccess = "";
			this.modalOptions.submittingError = "";

			Api.put(
				`/server-accounts/${this.serverAccount.id}/domains/${item.domain}`,
				formData
			)
				.then(() => {
					this.modalOptions.submittingSuccess = this.$t(
						"notification.hosting.domain.update.success",
						{ type: item.type_text }
					);
					this.highlightItem = item;
					this.reloadData(true);

					this.$store.dispatch("addAlert", {
						success: true,
						successMessage: this.modalOptions.submittingSuccess,
					});
					this.modalOptions.open = false;
				})
				.catch((error) => {
					this.modalOptions.submittingError = Api.getErrorMessage(error);

					this.$store.dispatch("addAlert", {
						success: false,
						errorMessage: this.modalOptions.submittingError,
					});
				})
				.finally(() => {
					this.modalOptions.persistent = false;
					this.modalOptions.submitting = false;
				});
		},

		// DELETE DOMAIN

		showDeleteModal(item) {
			this.resetModal();
			this.modalOptions.item = item;
			this.modalOptions.title = this.$t(
				"heading.hosting.domains.modal.delete.title"
			);
			this.modalOptions.color = "error";
			this.modalOptions.icon = "$alertwarning";

			this.modalOptions.formFields = [
				{
					message: `<b>${this.$t("message.confirmAction")}</b>`,
					label: this.$t("form.confirmDelete.domain"),
					name: "confirm",
					type: "checkbox",
					required: true,
				},
			];
			this.modalOptions.buttons.unshift({
				label: this.$t("button.delete"),
				color: "error",
				onclick: (modal) => {
					modal.$refs.form.validate() && this.deleteDomain(item);
				},
			});
			this.modalOptions.open = true;
		},
		deleteDomain(item) {
			this.modalOptions.persistent = true;
			this.modalOptions.submitting = true;
			this.modalOptions.submittingSuccess = "";
			this.modalOptions.submittingError = "";

			Api.delete(
				`/server-accounts/${this.serverAccount.id}/domains/${item.domain}`
			)
				.then(() => {
					this.modalOptions.submittingSuccess = this.$t(
						"notification.hosting.domain.delete.success",
						{ type: item.type_text }
					);
					this.reloadData();

					this.$store.dispatch("addAlert", {
						success: true,
						successMessage: this.modalOptions.submittingSuccess,
					});
					this.modalOptions.open = false;
				})
				.catch((error) => {
					this.modalOptions.submittingError = Api.getErrorMessage(error);

					this.$store.dispatch("addAlert", {
						success: false,
						errorMessage: this.modalOptions.submittingError,
					});
				})
				.finally(() => {
					this.modalOptions.persistent = false;
					this.modalOptions.submitting = false;
				});
		},

		// SET DOMAIN AS PRIMARY DOMAIN

		showSetAsPrimaryDomainModal(item) {
			this.resetModal();
			this.modalOptions.title = this.$t(
				"heading.hosting.domains.modal.setAsPrimary.title"
			);
			this.modalOptions.icon = "$starFullIcon";
			this.modalOptions.message = this.$t(
				"heading.hosting.domains.modal.setAsPrimary.message",
				{domain: item.domain}
			);

			this.modalOptions.item = item;

			this.modalOptions.buttons.unshift({
				label: this.$t("button.confirm"),
				color: "primary",
				onclick: () => {
					this.setAsPrimaryDomainModal(item)
				},
			});
			this.modalOptions.open = true;
		},
		setAsPrimaryDomainModal(item) {
			this.modalOptions.persistent = true;
			this.modalOptions.submitting = true;
			this.modalOptions.submittingSuccess = "";
			this.modalOptions.submittingError = "";

			Api.put(
				`/server-accounts/${this.serverAccount.id}/domains/${item.domain}/set-as-primary`
			)
				.then(() => {
					this.modalOptions.submittingSuccess = this.$t(
						"notification.hosting.domain.setAsPrimaryDomain.success",
						{ domain: item.domain }
					);
					this.reloadData();

					this.$store.dispatch("addAlert", {
						success: true,
						successMessage: this.modalOptions.submittingSuccess,
					});
					this.modalOptions.open = false;
				})
				.catch((error) => {
					this.modalOptions.submittingError = Api.getErrorMessage(error);

					this.$store.dispatch("addAlert", {
						success: false,
						errorMessage: this.modalOptions.submittingError,
					});
				})
				.finally(() => {
					this.modalOptions.persistent = false;
					this.modalOptions.submitting = false;
				});
		}
	}
}