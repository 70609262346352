<template>
  <div>
    <v-menu
      :close-on-content-click="false"
      open-on-hover
      bottom
      right
      :nudge-right="'12px'"
      close-delay="150"
      open-delay="150"
      offset-x
      min-width="308px"
      max-width="308px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="ml-1"
          x-small
          icon
          text
          color="error"
          v-on="on"
          v-bind="attrs"
        >
          <v-icon color="error" size="20">$alertcircle</v-icon>
        </v-btn>
      </template>
      <div class="pa-4">
        <div class="d-flex">
          <v-icon color="error" size="20"> $alertcircle</v-icon>
          <p class="error--text px-2 mb-2 p-xs">
            {{ $t("heading.instance.dnsTooltip.desc") }}
          </p>
        </div>
        <v-btn
          color="error"
          class="v-btn--fix-dns ml-7 px-3"
          small
          @click="openDnsModal()"
        >
          <v-icon class="mr-1" color="white" size="20"> $wrenchRight</v-icon>
          <span class="">{{ $t("heading.instance.dnsTooltip.button") }}</span>
        </v-btn>
      </div>
    </v-menu>
    <v-dialog
      ref="dialog"
      v-model="isOpenDnsModal"
      transition="custom-dialog-transition"
    >
      <div class="card-overlay" @click="openDnsModal()"></div>
      <v-card style="width: 560px">
        <v-card-title class="pb-6 flex justify-space-between align-center">
          <h4 class="font-weight-light">
            {{ $t("heading.instance.modal.setDomain.title") }}
          </h4>
          <v-btn icon x-small @click="openDnsModal()">
            <v-icon size="24">$close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pb-8">
          <DnsOptions
            :domain="domain"
            :onboardingSubdomain="onboardingSubdomain"
            :dnsSettings="dnsSettings"
            :ipv4Addresses="ipv4Addresses"
            :hasDnsServer="hasDnsServer"
            :nameservers="nameservers"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DnsOptions from "../DnsOptions.vue";
export default {
  components: { DnsOptions },
  props: {
    domain: String,
    onboardingSubdomain: [String, null],
    dnsSettings: Object,
    hasDnsServer: Boolean,
    ipv4Addresses: Array,
    nameservers: Array,
  },
  data() {
    return { isOpenDnsModal: false };
  },
  methods: {
    openDnsModal() {
      this.isOpenDnsModal = !this.isOpenDnsModal;
    },
  },
  watch: {
    isOpenDnsModal: function (value) {
      value
        ? this.$store.dispatch("lockBodyScroll")
        : this.$store.dispatch("unlockBodyScroll");
    },
  },
};
</script>

<style>
</style>