<template>
  <v-dialog
    transition="custom-dialog-transition"
    ref="dialog"
    v-model="isOpen"
  >
    <div class="card-overlay" @click="isOpen = !isOpen" />
    <v-card style="width: 600px" class="" v-if="isOpen">
      <v-card-title class="pb-0 flex justify-space-between align-center">
        <h4>
          {{ $t("heading.onboarding.transferDomain.title") }}
        </h4>
        <v-btn x-small icon @click="$emit('removeModal')" class="close-model">
          <v-icon>$close</v-icon>
        </v-btn>
      </v-card-title>

      <template v-if="selectedService.plan_settings.domain_transfer_enabled">
        <v-card-text height="800px" class="px-10 pt-8">
          <h6 class="p-2 font-weight-light">
            <i18next
              :translation="$t('heading.onboarding.transferDomain.subtitle')"
            >
              <template #domain><span class="font-weight-600">{{ domain.domain }}</span></template>
            </i18next>
          </h6>

          <p class="p-3 mt-2">
            <i18next
              :translation="$t('heading.onboarding.transferDomain.message.possible')"
            >
              <template #recordType><span class="font-weight-800">{{ dnsRecord.type }}</span>
              </template>
              <template #domain><span class="font-weight-800">{{ dnsRecord.name }}</span></template>
            </i18next>
          </p>

          <div class="recovery-code-box">
            {{ dnsRecord.content }}
            <copy-text-button
              :text="dnsRecord.content"
              :color="'primary'"
              size="24"
            />
          </div>
        </v-card-text>
      </template>
      <template v-else>
        <v-card-text height="800px" class="px-10 pt-8">
          <h6 class="p-2 font-weight-light">
            <i18next
              :translation="$t('heading.onboarding.transferDomain.subtitle')"
            >
              <template #domain><span class="font-weight-600">{{ domain.domain }}</span></template>
            </i18next>
          </h6>
          <p class="p-3 mt-2">
            {{ $t('heading.onboarding.transferDomain.message.impossible') }}
          </p>
        </v-card-text>
      </template>

      <v-card-actions class="d-flex flex-column pb-6 px-10 pt-2 mt-4">
        <template v-if="selectedService.plan_settings.domain_transfer_enabled">
          <v-btn
            x-large
            elevation="0"
            color="primary"
            block
            @click="handleTransferDomain"
            :loading="submitting"
          >{{ $t('button.transferNow') }}
          </v-btn>
          <v-btn
            x-large
            elevation="0"
            class="mx-0 mt-2"
            color="gray"
            text
            block
            @click="$emit('removeModal')"
          >
            <span class="p-1 font-weight-light gray--text text--darken-1">
              {{ $t("button.back") }}
            </span>
          </v-btn>
        </template>
        <template v-else>
          <v-btn
            x-large
            elevation="0"
            color="primary"
            block
            @click="$emit('removeModal')"
          >{{ $t('button.back') }}
          </v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CopyTextButton from "../buttons/CopyTextButton.vue";

export default {
  name: "TransferDomainModal",
  components: {CopyTextButton},
  props: {
    isOpen: Boolean,
    selectedService: Object,
    domain: Object,
    dnsRecord: Object
  },
  data() {
    return {
      submitting: false,
    }
  },
  methods: {
    handleTransferDomain() {
      this.$emit('transfer-domain', this.domain);
    }
  },
  watch: {
    isOpen: function (value) {
      value
        ? this.$store.dispatch("lockBodyScroll")
        : this.$store.dispatch("unlockBodyScroll");
    },
  },
}
</script>

<style scoped lang="scss">
.recovery-code-box {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 60px;
  color: var(--v-primary-base);
  background: rgba(7, 192, 126, 0.05);
  border-radius: 6px;
  text-transform: uppercase;
  font-weight: $font-weight-bold;
  word-wrap: anywhere;
  padding: 8px;
  min-height: 80px !important;
}
</style>